.pagination-sliders {
  position: absolute;
  top: 570px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 50;
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination-sliders span {
  width: 3rem;
  height: 0.5rem;
  cursor: pointer;
  margin: auto;
  text-align: center;
}
.pagination-sliders span.dot-slider {
  background-color: #A1AE2C;
}
.pagination-sliders span.active {
  background-color: white;
}

.anchor-to-policy {
  margin-left: 1.5rem;
  color: white;
  opacity: 0.7;
}
.anchor-to-policy:hover {
  color: white;
}

@media only screen and (max-width: 767.98px) {
  #footer .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }
}
.account-page {
  height: calc(100vh - 120px);
  padding-top: 108px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
}
.account-page h1.sectionTitle {
  display: block;
  font-size: 45px;
  font-weight: 400;
  font-family: Oswald, Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  line-height: 0.8;
  letter-spacing: 8px;
  margin-bottom: 80px;
  color: #000;
}
.account-page .input-form {
  width: 100%;
  max-width: 550px;
  position: relative;
  height: 50px;
  background: #FFF;
  border-radius: 0;
  border: 1px solid #CCC;
  margin-bottom: 20px;
  font-size: 14px;
  font-family: Oswald, Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #333;
  font-weight: 300;
  letter-spacing: 3px;
  padding-left: 19px;
}
.account-page button.submit {
  width: fit-content;
  height: 50px;
  padding: 0 1.5rem;
  background: #A1AE2C;
  border-radius: 4px;
  color: #000;
  font-size: 14px;
  font-family: Oswald, Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  border: none;
  transition: all ease 400ms;
  -moz-transition: all ease 400ms;
  -webkit-transition: all ease 400ms;
  -ms-transition: all ease 400ms;
  -o-transition: all ease 400ms;
}
.account-page button.submit:hover {
  background: #208667;
  color: #fff;
}
.account-page .btn-recover {
  padding-left: 33px;
  line-height: 1.5;
  font-size: 14px;
  font-family: Oswald, Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #000;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.account-page .btn-remember {
  color: #999999;
  font-size: 12px;
  font-weight: 300;
  font-family: Oswald, Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  position: relative;
  padding-left: 10px;
  letter-spacing: 2px;
  line-height: 3rem;
  margin: 0 0 15px 0;
  cursor: pointer;
}
.account-page .phrase {
  color: #999999;
  font-size: 10px;
  font-family: Oswald, Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  position: relative;
  padding-left: 10px;
  letter-spacing: 2px;
  line-height: 3rem;
  margin: 0 0 15px 0;
}
@media only screen and (min-width: 425px) and (max-width: 767.98px) {
  .account-page .input-form {
    max-width: 320px;
  }
  .account-page .group-actions {
    display: flex;
    flex-direction: column;
  }
  .account-page .btn-recover {
    padding-left: 0;
    padding-top: 33px;
  }
}
@media only screen and (max-width: 767.98px) {
  .account-page h1.sectionTitle {
    font-size: 25px;
    width: 75%;
    text-align: center;
  }
  .account-page .input-form {
    max-width: unset;
  }
}