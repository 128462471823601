
.pagination-sliders {
  position: absolute;
  top: 570px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 50;
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    width: 3rem;
    height: 0.5rem;
    cursor: pointer;
    margin: auto;
    text-align: center;
  }

  span.dot-slider {
    background-color: $greenLight;
  }

  span.active {
    background-color: white;
  }
}
