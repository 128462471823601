.anchor-to-policy {
  margin-left: 1.5rem;
  color: white;
  opacity: 0.7;
  &:hover {
    color: white;
  }
}

@media only screen and (max-width: 767.98px) {
  #footer .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }
}
